import './ficha_habitacion.scss';

import { graphql, navigate } from 'gatsby';

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { MediaMatcher, MediaServerRender } from 'react-media-match';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import { Fenrir } from '../src/components/Fenrir/Fenrir';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import Loki from '../src/components/Loki/lokipane';
import Layout from '../src/components/Layout/Layout';
import LayoutHotel from '../src/components/LayoutHotels/Main';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import Ymir from '../src/components/Ymir/Ymir';
import SEO from '../src/helpers/seo';
import buildFunnelUrl from '../src/helpers/buildFunnelUrl';
import tt from '../src/helpers/translation';

const findDestination = require('../src/helpers/findDestination');
const urls = require('../src/constants/urls');

const styles = () => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    border: 'none',
    fontFamily: 'Lato',
  },

  default_tabStyle: {
    backgroundColor: '#FFFFFF',
    opacity: 0.8,
    height: '50px',
    width: 'auto',
    padding: '5px',
    marginRight: '3px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '22px',
    textAlign: 'center',
    boxShadow: '0 2px 4px 0 rgb(0,0,0.5);',
    fontFamily: 'Lato',
  },

  active_tabStyle: {
    backgroundColor: '#FFFFFF',
    opacity: 1,
    height: '60px',
    width: 'auto',
    padding: '5px',
    borderRadius: '0 0 10px 10px',
    marginRight: '3px',
    fontWeight: '900',
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#1396D1',
    boxShadow: '0 2px 4px 0 rgb(0,0,0.5);',
    fontFamily: 'Lato',
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  Wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    width: '100%',
    fontFamily: 'Lato',
  },
  labelContainer: {
    marginTop: '10px',
    fontFamily: 'Lato',
  },
  active_labelContainer: {
    marginTop: '20px',
    fontFamily: 'Lato',
  },
});

const FichaHabitacion = ({ data, pageContext, classes }) => {
  const prepareForLoki = () => ({
    title: data.allHabitacionesSliderGalleryTitleBlock.edges[0].node.title,
    arrayImg: data.allHabitacionesSliderBlock.edges
      .map((item) => ({
        img: item.node.localImage ? item.node.localImage.childImageSharp.fluid : null,
        altImage: item.node.image_alt,
      }))
      .filter((item) => item.img !== null),
    bigArrayImg: data.bigLokiPics.edges
      .map((block) => ({
        img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
        altImage: block.node.image_alt,
      }))
      .filter((item) => item.img !== null),
  });

  const prepareForFenrirServices = () => ({
    list:
      data.allHabitacionesServiciosBlock.edges[0].node.body_left +
      data.allHabitacionesServiciosBlock.edges[0].node.body_right,
  });

  const prepareForFenrirEquipment = () => ({
    list:
      data.allHabitacionesServiciosBlock.edges[1].node.body_left +
      data.allHabitacionesServiciosBlock.edges[1].node.body_right,
  });
  const prepareForFenrirApartment = () => ({
    list:
      data.allHabitacionesServiciosBlock.edges[2].node.body_left +
      data.allHabitacionesServiciosBlock.edges[2].node.body_right,
  });

  const prepareForYmir = () => {
    const dc = data.allHabitacionesAdditionalLinks.edges[0].node.cards;
    const ret = {
      hotel: data.hotel,
      habitacion: data.habitacion,
      type: data.type,
      cards: [
        {
          title: dc._0.title,
          buttonCard: dc._0.buttonCard,
          link: dc._0.link,
          icon: dc._0.icon,
        },
        {
          title: dc._1.title,
          buttonCard: dc._1.buttonCard,
          link: dc._1.link,
          icon: dc._1.icon,
        },
        {
          title: dc._2.title,
          buttonCard: dc._2.buttonCard,
          link: dc._2.link,
          icon: dc._2.icon,
        },
      ],
    };
    return ret;
  };

  const prepareForH3 = () => ({
    title: tt('GALERIA DE FOTOS', pageContext.locale),
  });
  const heimdallData = {
    name: data.allHeaderBlockInfoGeneral.edges[0].node.title,
    image: {
      url: data.allHeaderBlockImageInfoGeneral.edges[0].node.localImage.childImageSharp.fluid,
      alt: data.allHeaderBlockImageInfoGeneral.edges[0].node.alt,
    },
    starsHotel: data.allHeaderBlockInfoGeneral.edges[0].node.stars,
    starsColor: 'white',
  };

  const [openmenu1, setopenmenu1] = useState(false);
  const [value, setvalue] = useState(pageContext.tab_name);

  const handleClick = () => {
    setopenmenu1(!openmenu1);
  };

  const handleDataChange = (type) => {
    const { hotel_name, tab_name } = pageContext;
    const lang = pageContext.locale;
    const validOptions = {
      hotelname: urls.templates_multilang.ficha_hoteles.options.hotelname[lang],
      section: urls.templates_multilang.ficha_hoteles.options.section[lang],
    };
    let section = '';

    const sectionBase = urls.templates_multilang.ficha_hoteles.options.section[lang];
    switch (type) {
      case 'INFO':
        section = sectionBase[0];
        break;
      case 'EXCLUSIVE':
        section = sectionBase[1];
        break;
      case 'ROOMS':
        section = sectionBase[2];
        break;
      case 'RESTAURANT':
        section = sectionBase[3];
        break;
      case 'SERVICES':
        section = sectionBase[4];
        break;
      case 'ARRIVE':
        section = sectionBase[5];
        break;
      default:
        section = sectionBase[0];
        break;
    }
    const localizedUrl = urls.templates_multilang.ficha_hoteles.urls.filter(
      (a) => a.lang === lang
    )[0];
    const dest = findDestination.findDestination(localizedUrl.urlGatsby, validOptions, [
      hotel_name,
      section,
    ]);

    navigate(dest);
  };

  const handleChangeTab = (event, value) => {
    handleDataChange(value);
  };

  const isMobileTrue = (
    <div className="tab-menu">
      <ListItem button className="firstlevelpasesmenu" onClick={() => handleClick()}>
        <ListItemText
          className="firstlevelpasesmenu-text"
          inset
          primary={tt('HABITACIONES', pageContext.locale)}
        />
        {openmenu1 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openmenu1} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className="secondpaseslevelmenu"
            value="INFO"
            onClick={() => handleDataChange('INFO')}
          >
            <ListItemText
              className="secondpaseslevelmenu-text"
              inset
              primary={tt('INFORMACIÓN GENERAL', pageContext.locale)}
            />
          </ListItem>

          <ListItem
            button
            className="secondpaseslevelmenu"
            value="EXCLUSIVE"
            onClick={() => handleDataChange('EXCLUSIVE')}
          >
            <ListItemText
              className="secondpaseslevelmenu-text"
              inset
              primary={tt('BENEFICIOS EXCLUSIVOS', pageContext.locale)}
            />
          </ListItem>

          <ListItem
            button
            className="secondpaseslevelmenu"
            value="ROOMS"
            onClick={() => handleDataChange('ROOMS')}
          >
            <ListItemText
              className="secondpaseslevelmenu-text"
              inset
              primary={tt('HABITACIONES', pageContext.locale)}
            />
          </ListItem>

          <ListItem
            button
            className="secondpaseslevelmenu"
            value="SERVICES"
            onClick={() => handleDataChange('SERVICES')}
          >
            <ListItemText
              className="secondpaseslevelmenu-text"
              inset
              primary={tt('SERVICIOS', pageContext.locale)}
            />
          </ListItem>

          <ListItem
            button
            className="secondpaseslevelmenu"
            value="RESTAURANT"
            onClick={() => handleDataChange('RESTAURANT')}
          >
            <ListItemText
              className="secondpaseslevelmenu-text"
              inset
              primary={tt('RESTAURANTES', pageContext.locale)}
            />
          </ListItem>

          <ListItem
            button
            className="secondpaseslevelmenu"
            value="ARRIVE"
            onClick={() => handleDataChange('ARRIVE')}
          >
            <ListItemText
              className="secondpaseslevelmenu-text"
              inset
              primary={tt('CÓMO LLEGAR', pageContext.locale)}
            />
          </ListItem>
        </List>
      </Collapse>
    </div>
  );

  const isBrowserTrue = (
    <Tabs
      value={value}
      onChange={handleChangeTab}
      centered
      variant="fullWidth"
      className="tab-head"
      classes={{ indicator: classes.indicator }}
    >
      <Tab
        label={tt('INFORMACIÓN GENERAL', pageContext.locale)}
        value="INFO"
        className={
          pageContext.tab_name === 'INFO' ? classes.active_tabStyle : classes.default_tabStyle
        }
        classes={{
          labelContainer:
            pageContext.tab_name === 'INFO'
              ? classes.active_labelContainer
              : classes.labelContainer,
          wrapper: classes.Wrapper,
        }}
      />
      <Tab
        label={tt('BENEFICIOS EXCLUSIVOS', pageContext.locale)}
        value="EXCLUSIVE"
        className={
          pageContext.tab_name === 'EXCLUSIVE'
          ? classes.active_tabStyle + " EXCLUSIVE" : classes.default_tabStyle + " EXCLUSIVE"} //eslint-disable-line
        classes={{
          labelContainer:
            pageContext.tab_name === 'EXCLUSIVE'
              ? classes.active_labelContainer
              : classes.labelContainer,
          wrapper: classes.Wrapper,
        }}
      />
      <Tab
        label={tt('HABITACIONES', pageContext.locale)}
        value="ROOMS"
        className={
          pageContext.tab_name === 'ROOMS'
          ? classes.active_tabStyle + " ROOMS" : classes.default_tabStyle + " ROOMS"} //eslint-disable-line
        classes={{
          labelContainer:
            pageContext.tab_name === 'ROOMS'
              ? classes.active_labelContainer
              : classes.labelContainer,
          wrapper: classes.Wrapper,
        }}
      />
      <Tab
        label={tt('SERVICIOS', pageContext.locale)}
        value="SERVICES"
        className={
          pageContext.tab_name === 'SERVICES'
          ? classes.active_tabStyle + " SERVICES" : classes.default_tabStyle + " SERVICES"} //eslint-disable-line
        classes={{
          labelContainer:
            pageContext.tab_name === 'SERVICES'
              ? classes.active_labelContainer
              : classes.labelContainer,
          wrapper: classes.Wrapper,
        }}
      />
      <Tab
        label={tt('RESTAURANTES', pageContext.locale)}
        value="RESTAURANT"
        className={
          pageContext.tab_name === 'RESTAURANT'
          ? classes.active_tabStyle + " RESTAURANT" : classes.default_tabStyle + " RESTAURANT"} //eslint-disable-line
        classes={{
          labelContainer:
            pageContext.tab_name === 'RESTAURANT'
              ? classes.active_labelContainer
              : classes.labelContainer,
          wrapper: classes.Wrapper,
        }}
      />
      <Tab
        label={tt('CÓMO LLEGAR', pageContext.locale)}
        value="ARRIVE"
        className={
          pageContext.tab_name === 'ARRIVE'
          ? classes.active_tabStyle + " ARRIVE" : classes.default_tabStyle + " ARRIVE"} //eslint-disable-line
        classes={{
          labelContainer:
            pageContext.tab_name === 'ARRIVE'
              ? classes.active_labelContainer
              : classes.labelContainer,
          wrapper: classes.Wrapper,
        }}
      />
    </Tabs>
  );

  const titleData = {
    title: data.allHabitacionTitleBlock.edges[0].node.title,
    description:
      data.allHabitacionTitleBlock.edges[0].node.description +
      data.allHabitacionTitleBlock.edges[0].node.description2,
  };
  const button1 = {
    cta: tt('/hoteles/check-in-online', pageContext.locale),
    size: 'small',
    color: 'white',
    ctaText: tt('CHECKIN ONLINE', pageContext.locale),
  };

  const button2 = {
    cta: buildFunnelUrl(pageContext.locale, 'hotels'),
    size: 'small',
    color: 'orange',
    ctaText: tt('RESERVAR AHORA', pageContext.locale),
  };
  return (
    <Layout
      layoutFromOld
      locale={pageContext.locale}
      pageName={pageContext.pageName}
      options={pageContext.options}
    >
      <SEO
        title={data.allHabitacionesSeoData.edges[0].node._0.title}
        description={data.allHabitacionesSeoData.edges[0].node._1.description}
        pathname={pageContext.url}
        lang={pageContext.locale}
      />
      <LayoutHotel
        urlCTA={data.allHotelIconBlock.edges.length && data.allHotelIconBlock.edges[0].node.ctaURL}
        button2={button2}
        button1={button1}
        heimdallData={heimdallData}
        promos={data.allPromoInfoGeneralSubBlock.edges}
        layoutImage={
          data.allHeaderBlockImageInfoGeneral.edges[0].node.localImage.childImageSharp.fluid
        }
        layoutData={data.allHeaderBlockInfoGeneral.edges[0].node}
        icon={data.allHotelIconBlock.edges.length && data.allHotelIconBlock.edges[0].node.Image}
      >
        <div className="rooms-details-cont">
          <BlueArrow />
          <NeoAesir
            locale={pageContext.locale}
            pageName="ficha_detalle_habitacion"
            options={pageContext.options}
            title={data.allFichaHabitacionBreadCrumbBlock.edges[0].node.name}
          />
          <MediaServerRender predicted="desktop" hydrated>
            <MediaMatcher mobile={isMobileTrue} desktop={isBrowserTrue} />
          </MediaServerRender>
          <H2 data={titleData} />
          <H3 data={prepareForH3()} />
          <Loki data={prepareForLoki()} />
          <div className="first-fenrir-element">
            <H3 data={data.allHabitacionesServiciosBlock.edges[0].node} />
            <Fenrir data={prepareForFenrirServices()} />
          </div>
          <div
            className={
              data.allHabitacionesServiciosBlock.edges.length > 2
                ? 'first-fenrir-element'
                : 'second-fenrir-element'
            }
          >
            <H3 data={data.allHabitacionesServiciosBlock.edges[1].node} />
            <Fenrir data={prepareForFenrirEquipment()} />
          </div>
          {data.allHabitacionesServiciosBlock.edges.length > 2 ? (
            <div className="second-fenrir-element">
              <H3 data={data.allHabitacionesServiciosBlock.edges[2].node} />
              <Fenrir data={prepareForFenrirApartment()} />
            </div>
          ) : null}
          <Ymir data={prepareForYmir()} />
        </div>
      </LayoutHotel>
    </Layout>
  );
};

/* eslint-disable react/require-default-props */
FichaHabitacion.propTypes = {
  data: PropTypes.shape({}),
  pageContext: PropTypes.shape({}),
};
/* eslint-enable react/require-default-props */

export default withStyles(styles)(FichaHabitacion);

export const pageQuery = graphql`
  query allFichaHabitacionQuery($hotel_name: String!, $habitacion_name: String!, $locale: String!) {
    allHabitacionesSeoData(
      filter: {
        _3: { habitacion: { eq: $habitacion_name } }
        _2: { hotel: { eq: $hotel_name } }
        lang: { eq: $locale }
      }
    ) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allHeaderBlockInfoGeneral(
      filter: { tab: { eq: "" }, hotel: { eq: $hotel_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          hotel
          tab
          link
          colorRound
          title
          subTitle
          stars
          button1 {
            cta
            size
            color
            ctaText
          }
          button2 {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allHeaderBlockImageInfoGeneral(filter: { tab: { eq: "" }, hotel: { eq: $hotel_name } }) {
      edges {
        node {
          alt
          hotel
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allHabitacionTitleBlock(
      filter: {
        habitacion: { eq: $habitacion_name }
        hotel: { eq: $hotel_name }
        lang: { eq: $locale }
      }
    ) {
      edges {
        node {
          hotel
          title
          description
          description2
        }
      }
    }
    allHabitacionesSliderGalleryTitleBlock(
      filter: {
        habitacion: { eq: $habitacion_name }
        hotel: { eq: $hotel_name }
        lang: { eq: $locale }
      }
    ) {
      edges {
        node {
          title
        }
      }
    }
    allHabitacionesSliderBlock(
      filter: {
        habitacion: { eq: $habitacion_name }
        hotel: { eq: $hotel_name }
        lang: { eq: $locale }
      }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    bigLokiPics: allHabitacionesSliderBlock(
      filter: {
        habitacion: { eq: $habitacion_name }
        hotel: { eq: $hotel_name }
        lang: { eq: $locale }
      }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allHabitacionesServiciosBlock(
      filter: {
        habitacion: { eq: $habitacion_name }
        hotel: { eq: $hotel_name }
        lang: { eq: $locale }
      }
    ) {
      edges {
        node {
          title
          body_left
          body_right
        }
      }
    }
    allHotelRoomsIconBlock(
      filter: {
        habitacion: { eq: $habitacion_name }
        hotel: { eq: $hotel_name }
        lang: { eq: $locale }
      }
    ) {
      edges {
        node {
          ctaURL
        }
      }
    }
    allHotelIconBlock(filter: { hotel: { eq: $hotel_name }, lang: { eq: $locale } }) {
      edges {
        node {
          Image
          ctaURL
        }
      }
    }
    allHabitacionesAdditionalLinks(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          cards {
            _0 {
              title
              buttonCard
              link
              icon
            }
            _1 {
              title
              buttonCard
              link
              icon
            }
            _2 {
              title
              buttonCard
              link
              icon
            }
          }
        }
      }
    }
    allPromoInfoGeneralSubBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: "" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          imgsize
          stars
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allFichaHabitacionBreadCrumbBlock(
      filter: {
        habitacion: { eq: $habitacion_name }
        hotel: { eq: $hotel_name }
        lang: { eq: $locale }
      }
    ) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    habitacionesRichData {
      rich_markup {
        color_css_class
        og_description
        og_title
        twitter_description
        twitter_title
        created_at
        updated_at
        rich_markup_type
      }
    }
  }
`;
